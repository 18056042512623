.st2bFaq-enter {
  max-height: 0px;
  overflow: hidden;
}
.st2bFaq-enter-active {
  max-height: calc(100vw * 0.1);
  transition: max-height 300ms ease;
}
.st2bFaq-exit {
  max-height: calc(100vw * 0.1);
}
.st2bFaq-exit-active {
  max-height: 0;
  transition: max-height 300ms ease;
  overflow: hidden;
}

.st2bFaqMb-enter {
  max-height: 0px;
  overflow: hidden;
}
.st2bFaqMb-enter-active {
  max-height: calc(100vw * 0.89);
  transition: max-height 300ms ease;
}
.st2bFaqMb-exit {
  max-height: calc(100vw * 0.89);
}
.st2bFaqMb-exit-active {
  max-height: 0;
  transition: max-height 300ms ease;
  overflow: hidden;
}
