.button {
    float: left;
    position: relative;
    /* margin: 0 -1.3vw; */
}
.Pig {
    top: -3.5vw!important;
}

.TitlePig {
    /* background: red; */
    width: 10vw!important;
}
.number {
    font-size: 15vw!important;
}
.textbox {
    margin: 1.6vw auto;
    text-align: center;
    width: 85%;
}
.textbox img {
    width: 4vw;
    height: 2.3vw;
}

.clock {
    width: 3.2vw;
    height: 2vw;
}

.red {
    color: red!important;
}
.left {
    float: left;
    /* background-color: blue; */
}

.clear {
    clear: both;
}
.ShiftTop {
    top: -1.7vw;
    position: relative;
}