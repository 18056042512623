.CopyButton p {
  right: 8%;
  top: 29%;
  font-size: 1.8vw !important;
}

.CopyButton img {
  width: 9vw !important;
}

.CopyButton {
  outline: none;
  padding: 0%;
}

.CopyButton div {
  display: flex;
  place-content: center;
}
.Copy {
  width: 5vw;
  position: absolute;
  right: -1vw;
  top: 0.5vw;
}
.Copy p {
  /* right: 1%; */
  /* top: 29%; */
  font-size: 1.3vw !important;
  position: absolute;
}
.Copy div {
  display: flex;
  place-content: center;
}
.Copy img {
  width: 7vw !important;
}

.With p {
  right: 0.8vw;
  top: 25%;
  font-size: 1vw !important;
  z-index: 30;
}

.With img {
  position: absolute;
  width: 7vw !important;
  margin-left: -2.5vw;
  margin-top: -1.75vw;
}
.Bcopy1 img {
  width: 6vw !important;
  position: absolute;
  top: 8%;
  right: 35%;
}

.Bcopy1 p {
  top: -0.2vw;
  font-size: 1.1vw !important;
  z-index: 10;
  position: absolute;
  right: 22vw;
}

.pincode-input-text {
  width: 4vw;
  height: 4vw;
  border-radius: 60%;
  text-align: center;
  font-size: 2vw;
  font-weight: 600;
  border: 1vw solid var(--exp-text-color);
  /* border: 1vw solid red; */
  background-color: black;
  color: #FF7000;
  outline: transparent;
  /* margin-left: 2vw; */
}

.ml-2 {
  margin-left: 0.5vw;
}

.ml-2 {
  margin-right: 0.5vw;
}

/* .ml-2, .mx-2 {
  margin-right: 1vw;
}

.ml-2, .mx-2{
  margin-left: .5rem !important;
}

.ml-2, .mx-2{
  margin-right: .5rem !important;
} */
.input {
  font-size: 2vw;
  font-weight: 400;
  border: 0 solid none;
}

