.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

/* .sb2t-enter {
  opacity: 0;
  transform: scale(0.9);
  transform: translateY(20%);
}
.sb2t-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.sb2t-exit {
  opacity: 1;
}
.sb2t-exit-active {
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
  transform: translateY(20%);
} */

.st2b-enter {
  opacity: 0;
  /* transform: scale(0.9); */
  transform: translateY(-20%);
}
.st2b-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.st2b-exit {
  opacity: 1;
}
.st2b-exit-active {
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
  transform: translateY(20%);
}

.sl2r-enter {
  opacity: 0;
  /* transform: scale(0.9); */
  transform: translateX(-20%);
}
.sl2r-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.sl2r-exit {
  opacity: 1;
}
.sl2r-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  transform: translateX(-20%);
}
