
#reader canvas{
    width: 32vw;
    height: 20vw;
    display: flex;
}
#fileUpload canvas{
    width: 32vw;
    height: 20vw;
    display: flex;
}