.sr2l-enter {
  opacity: 0;
  transform: scale(0.9);
  transform: translateX(100%);
}
.sr2l-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.sr2l-exit {
  opacity: 1;
}
.sr2l-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  transform: translateX(100%);
}

.sl2r-enter {
  opacity: 0;
  transform: scale(0.9);
  transform: translateX(-100%);
}
.sl2r-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.sl2r-exit {
  opacity: 1;
}
.sl2r-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  transform: translateX(-100%);
}

.st2b-enter {
  opacity: 0;
  transform: scale(0.9);
  transform: translateY(-100%);
}
.st2b-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.st2b-exit {
  opacity: 1;
}
.st2b-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  transform: translateY(-100%);
}

.sb2t-enter {
  opacity: 0;
  transform: scale(0.9);
  transform: translateY(100%);
}
.sb2t-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.sb2t-exit {
  opacity: 1;
}
.sb2t-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  transform: translateY(100%);
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.pop {
  margin-top: 2.5% !important;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
