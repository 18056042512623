body * {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: Roboto; */
  text-decoration-line: none;
  font-family: "Prompt", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* fixed fullscreen not scroll in landing page */
div#root:fullscreen {
  overflow: scroll !important;
}
div#root:-ms-fullscreen {
  overflow: scroll !important;
}
div#root:-webkit-full-screen {
  overflow: scroll !important;
}
div#root:-moz-full-screen {
  overflow: scroll !important;
}
/* end fixed fullscreen not scroll in landing page */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Prompt;
  src: url("./Fonts/Roboto/Roboto-Light.woff") format("woff");
}
@font-face {
  /* font-family: RobotoBold; */
  font-family: Prompt;
  src: url("./Fonts/Prompt/Prompt-Medium.woff") format("woff");
  /* src: url('./Fonts/Roboto/Roboto-Medium.woff') format('woff') */
}
@font-face {
  font-family: Prompt;
  src: url("./Fonts/Prompt/Prompt-Regular.woff") format("woff");
}
@font-face {
  font-family: Prompt;
  src: url("./Fonts/Prompt/Prompt-Medium.woff") format("woff");
}
@font-face {
  font-family: Prompt-Bold;
  src: url("./Fonts/Prompt/Prompt-Bold.woff") format("woff");
}

.Roboto {
  font-family: Roboto !important;
}

.Prompt {
  font-family: Prompt !important;
}
