.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  overflow-x: hidden;
  margin: 0;
  background-color: #000;
}

/* 
.slotwrapper {
  overflow: hidden;
  display: inline-block;
  
  height: 8.9vw;
  
}

.slotwrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  float: left;
}

.slotwrapper ul {
  width: 6.4vw;
  height: 10.9vw;
  font-size: 200px;
  line-height: 9vw;
  text-align: center;
  padding-top: 3vw;
}

.slotwrapper ul li img {
  vertical-align: top;
} */

/* @media screen and (max-width: 480px) {
  .slotwrapper {
    width: 85%;
    text-align: center;

    padding-left: 48vw;
    height: 15vw;
  }

  .slotwrapper ul {
    width: 29.5%;
    height: 40vw;
    margin-top: 9vw;
  }


  .slotwrapper {
    overflow: hidden;
    display: inline-block;
    height: 33.9vw;
    margin-left: -39vw;
    margin-top: -3vw;
  }
} */

/* @media screen and (max-width: 768px) {
  .slotwrapper {
    height: 100px;
  }
  .slotwrapper ul {
    height: 100px;
    font-size: 100px;
    line-height: 100px;
  }
} */