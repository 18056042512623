@font-face {
  font-family: 'spinix_ico';
  src: url('../font/spinix_ico.eot?75532198');
  src: url('../font/spinix_ico.eot?75532198#iefix') format('embedded-opentype'),
       url('../font/spinix_ico.woff2?75532198') format('woff2'),
       url('../font/spinix_ico.woff?75532198') format('woff'),
       url('../font/spinix_ico.ttf?75532198') format('truetype'),
       url('../font/spinix_ico.svg?75532198#spinix_ico') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'spinix_ico';
    src: url('../font/spinix_ico.svg?75532198#spinix_ico') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "spinix_ico";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ico_live:before { content: '\e800'; } /* '' */
.icon-ico_home:before { content: '\e801'; } /* '' */
.icon-ico_search:before { content: '\e802'; } /* '' */
.icon-ico_crash:before { content: '\e803'; } /* '' */
.icon-ico_hot:before { content: '\e805'; } /* '' */
.icon-ico_new:before { content: '\e806'; } /* '' */
.icon-ico_bonus:before { content: '\e807'; } /* '' */
.icon-ico_pvp:before { content: '\e808'; } /* '' */
.icon-ico_arcade:before { content: '\e80a'; } /* '' */
.icon-ico_card:before { content: '\e80b'; } /* '' */
.icon-ico_fav:before { content: '\e80c'; } /* '' */
.icon-ico_fish:before { content: '\e80d'; } /* '' */
.icon-ico_slot:before { content: '\e80e'; } /* '' */
.icon-ico_lotto:before { content: '\e810'; } /* '' */
.icon-ico_ball:before { content: '\e813'; } /* '' */
.icon-ico_more:before { content: '\e814'; } /* '' */
.icon-ico_history:before { content: '\e818'; } /* '' */
